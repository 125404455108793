export class ServiceCommonConstants {
  public static readonly BASE_CRM_URL = '/crm';
  public static readonly BASE_TASK_URL = `${this.BASE_CRM_URL}/task-service`;
  public static readonly BASE_ROAD_MAP_URL = `${this.BASE_CRM_URL}/road-map`;
  public static readonly BASE_QUESTIONNAIRE_URL = `${this.BASE_CRM_URL}/questionnaire`;
  public static readonly BASE_LANDING_URL = `${this.BASE_CRM_URL}/landing-service`;
  public static readonly BASE_ADMIN_URL = `${this.BASE_CRM_URL}/administration-service`;
  public static readonly BASE_TEST_RESULT_ANALYZE_URL = `${this.BASE_CRM_URL}/test-result-analyze-service`;
  public static readonly BASE_PRODUCT_URL = `${this.BASE_CRM_URL}/product-service`;
  public static readonly BASE_CONTENT_URL = `${this.BASE_CRM_URL}/content`;
  public static readonly BASE_CHAT_SERVICE_URL = `${this.BASE_CRM_URL}/chat-service`;
  public static readonly BASE_PUBLIC_USER_URL = `api/public/user`;
  public static readonly BASE_QUESTION_GENERATE_URL = `${this.BASE_CRM_URL}/question-generate`;

}
