import {DOCUMENT} from "@angular/common";
import {
    Component,
    Inject,
    ElementRef,
    OnInit,
    Renderer2,
    AfterViewInit,
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfigService} from "src/@cliniva/config/config.service";
import {AuthService} from "src/app/core/service/auth.service";
import {RightSidebarService} from "src/app/core/service/rightsidebar.service";
import {LanguageService} from "src/app/core/service/language.service";
import {UnsubscribeOnDestroyAdapter} from "src/app/shared/UnsubscribeOnDestroyAdapter";
import {NavigationService} from "../../../core/service/navigation.service";
import {AdministrationService} from "../../../core/service/administration.service";
import {OrganizationService} from "../../../core/service/organization.service";
import {DataService} from "../../../core/service/data.service";
import {PersistenceService} from "../../../core/service/common/persistence.service";
import {SharedService} from "../../../core/service/shared.service";

const document: any = window.document;

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.sass"],
})
export class HeaderComponent
    extends UnsubscribeOnDestroyAdapter
    implements OnInit, AfterViewInit {
    public config: any = {};
    userImg: string;
    mobileMedia: any = window.matchMedia("(max-width:797px)");

    isMobile = false;
    homePage: string;
    isNavbarCollapsed = true;
    // flagvalue;
    selectedLanguage;
    langStoreValue: string;
    // defaultFlag: string;
    defaultLang: string;
    userFullName: string;
    userNameToDisplay: string;
    userRoleToDisplay: string;
    user: any;
    orgId: number;
    employee: any;
    schoolInfo;
    showMenu = true;

    constructor(@Inject(DOCUMENT) private document: Document,
                private renderer: Renderer2,
                private navService: NavigationService,
                private rightSidebarService: RightSidebarService,
                private configService: ConfigService,
                private authService: AuthService,
                private router: Router,
                private administrationService: AdministrationService,
                public languageService: LanguageService,
                private organizationService: OrganizationService,
                private dataService: DataService,
                private persistence: PersistenceService,
                private sharedService: SharedService) {
        super();
        if (this.mobileMedia.matches) {
            this.isMobile = true;
        }
        this.user = this.persistence.getCurrentUser();
        if (this.user) {
            this.orgId = this.user.orgId;
        }
    }

    listLang = [
        // {text: "English", flag: "assets/images/flags/us.svg", lang: "en"},
        // {text: "Kazakh", flag: "assets/images/flags/kz.png", lang: "kz"},
        {text: "ENG", flag: "", lang: "en"},
        {text: "ҚАЗ", flag: "", lang: "kz"},
    ];

    toggleSideNav() {
        this.navService.setShowNav(true);
    }

    ngOnInit() {
        this.config = this.configService.configData;
        const userRole = this.authService.currentUserValue.roles;
        this.userImg = this.authService.currentUserValue.img;
        this.userImg = this.authService.currentUserValue.img;
        this.userFullName = this.authService.currentUserValue.firstName + " " + this.authService.currentUserValue.lastName;
        this.userNameToDisplay = this.authService.currentUserValue.lastName + " " + this.authService.currentUserValue.firstName.substring(0, 1) + ".";
        this.userImg = this.authService.currentUserValue.img;
        this.orgId = this.authService.currentUserValue.orgId;


        if (userRole === "ROLE_SUPER_ADMIN") {
            this.homePage = "/subject";
            this.userRoleToDisplay = "Супер админ";
        } else if (userRole === "ROLE_CONTENT_MANAGER") {
            this.userRoleToDisplay = "Контент менеджер";
        }  else if (userRole === "ROLE_SALE_MANAGER") {
            this.homePage = "/sales/b2c";
            this.userRoleToDisplay = "Сату менеджер";
        } else if (userRole === "ROLE_ADMIN") {
            this.userRoleToDisplay = "Админ";
        }  else if (userRole === "ROLE_USER") {
            this.userRoleToDisplay = "Қолданушы";
        } else if (userRole === "ROLE_TEACHER") {
            this.userRoleToDisplay = "Мұғалім";
        } else {
          this.homePage = "admin/dashboard/main";
        }

        this.langStoreValue = localStorage.getItem("lang");
        const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
        // this.selectedLanguage = val.map((element) => element.text);
        // if (val.length === 0) {
        //     if (this.flagvalue === undefined) {
        //          this.defaultFlag = "assets/images/flags/us.svg";
        //     }
        // } else {
        //     this.flagvalue = val.map((element) => element.flag);
        // }
        if (val.length === 0) {
            if (this.selectedLanguage === undefined) {
                this.defaultLang = "ENG";
            }
        } else {
            this.selectedLanguage = val.map((element) => element.text);
        }

        this.getOrgNameById();
        this.sharedService.sidebarVisibility$.subscribe((visibility) => {
           if (visibility) {
              this.showMenu = false;
           }
        });
    }

    ngAfterViewInit() {
        // set theme on startup
        if (localStorage.getItem("theme")) {
            this.renderer.removeClass(this.document.body, this.config.layout.variant);
            this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
        } else {
            this.renderer.addClass(this.document.body, this.config.layout.variant);
        }

        if (localStorage.getItem("menuOption")) {
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem("menuOption")
            );
        } else {
            this.renderer.addClass(
                this.document.body,
                "menu_" + this.config.layout.sidebar.backgroundColor
            );
        }

        if (localStorage.getItem("choose_logoheader")) {
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem("choose_logoheader")
            );
        } else {
            this.renderer.addClass(
                this.document.body,
                "logo-" + this.config.layout.logo_bg_color
            );
        }

        if (localStorage.getItem("sidebar_status")) {
            if (localStorage.getItem("sidebar_status") === "close") {
                this.renderer.addClass(this.document.body, "side-closed");
                this.renderer.addClass(this.document.body, "submenu-closed");
            } else {
                this.renderer.removeClass(this.document.body, "side-closed");
                this.renderer.removeClass(this.document.body, "submenu-closed");
            }
        } else {
            if (this.config.layout.sidebar.collapsed === true) {
                this.renderer.addClass(this.document.body, "side-closed");
                this.renderer.addClass(this.document.body, "submenu-closed");
            }
        }
    }

    callFullscreen() {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    setLanguage(text: string, lang: string, flag: string) {
        this.selectedLanguage = text;
        // this.flagvalue = flag;
        this.langStoreValue = lang;
        this.languageService.setLanguage(lang);
    }

    mobileMenuSidebarOpen(event: any, className: string) {
        const hasClass = event.target.classList.contains(className);
        if (hasClass) {
            this.renderer.removeClass(this.document.body, className);
        } else {
            this.renderer.addClass(this.document.body, className);
        }
    }

    callSidemenuCollapse() {
        const hasClass = this.document.body.classList.contains("side-closed");
        if (hasClass) {
            this.renderer.removeClass(this.document.body, "side-closed");
            this.renderer.removeClass(this.document.body, "submenu-closed");
        } else {
            this.renderer.addClass(this.document.body, "side-closed");
            this.renderer.addClass(this.document.body, "submenu-closed");
        }

        this.dataService.updateData(hasClass);
    }

    getOrgNameById() {
        if (this.authService && this.authService.currentUserValue && this.authService.currentUserValue.orgId) {
            this.organizationService.getOrganization(this.authService.currentUserValue.orgId).subscribe(res => {
                this.schoolInfo = res;
            });
        }
    }
    truncateText(text: string, maxLength: number = 70): string {
        if (text && text.length > maxLength) {
            return text.substr(0, maxLength) + '...';
        }
        return text;
    }


    logout() {
        this.subs.sink = this.authService.logout().subscribe((res) => {
            if (!res.success) {
                this.router.navigate(["/authentication/signin"]);
            }
        });
    }
}
