import {RouteInfo} from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
    {
        path: "content",
        title: "MENUITEMS.CONTENT_MANAGER.TEXT",
        iconType: "material-icons-two-tone",
        icon: "bookmark",
        groupTitle: true,
        role: [],
        submenu: [
            {
                path: "landing",
                title: "MENUITEMS.LANDING.TEXT",
                iconType: "material-icons-two-tone",
                icon: "pageview",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [],
            },
            {
                path: "school-theme",
                title: "MENUITEMS.SCHOOL-THEME.TEXT",
                iconType: "material-icons-two-tone",
                icon: "pageview",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [],
            },
            {
                path: "partner-logo",
                title: "MENUITEMS.PARTNER-LOGO.TEXT",
                iconType: "material-icons-two-tone",
                icon: "pageview",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [],
            },
            {
                path: "subject",
                title: "MENUITEMS.SUBJECT.TEXT",
                iconType: "material-icons-two-tone",
                icon: "subject",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [],
            },
            {
                path: "application",
                title: "MENUITEMS.APPLICATION.TEXT",
                iconType: "material-icons-two-tone",
                icon: "announcement",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER", "ROLE_SERVICE"],
                submenu: [],
            },
            {
                path: "season",
                title: "MENUITEMS.SEASON.TEXT",
                iconType: "material-icons-two-tone",
                icon: "dataset",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN"],
                submenu: [],
            },
            {
                path: "myGroups",
                title: "My groups",
                iconType: "material-icons-two-tone",
                icon: "group",
                groupTitle: false,
                role: ["ROLE_TEACHER"],
                submenu: [],
            },
            {
                path: "/home-tasks",
                title: "Homeworks",
                iconType: "material-icons-two-tone",
                icon: "inventory_2",
                groupTitle: false,
                role: ["ROLE_TEACHER"],
                submenu: [],
            },
            {
                path: "/template",
                title: "MENUITEMS.TEMPLATE.TEXT",
                iconType: "material-icons-two-tone",
                icon: "description",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER", "ROLE_TEACHER"],
                submenu: [],
            },
            {
                path: "/template-type",
                title: "MENUITEMS.TEMPLATE_TYPE.TEXT",
                iconType: "material-icons-two-tone",
                icon: "description",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [],
            },
            {
                path: "question",
                title: "MENUITEMS.QUESTION.TEXT",
                iconType: "material-icons-two-tone",
                icon: "question_answer",
                groupTitle: true,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [
                    {
                        path: "question-type",
                        title: "MENUITEMS.QTYPE.TEXT",
                        iconType: "material-icons-two-tone",
                        icon: "rule",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                        submenu: [],
                    },
                    {
                        path: "question",
                        title: "MENUITEMS.QLIST.TEXT",
                        iconType: "material-icons-two-tone",
                        icon: "question_answer",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                        submenu: [],
                    },
                    {
                        path: "question-resource",
                        title: "MENUITEMS.QRESOURCE.TEXT",
                        iconType: "material-icons-two-tone",
                        icon: "note_add",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                        submenu: [],
                    },
                    {
                        path: "question-generate",
                        title: "MENUITEMS.QGENERATE.TEXT",
                        iconType: "material-icons-two-tone",
                        icon: "settings",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                        submenu: [],
                    },
                ],
            },
            {
                path: "syllabus",
                title: "Syllabus",
                iconType: "material-icons-two-tone",
                icon: "source",
                groupTitle: true,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [
                    {
                        path: "/syllabus/camp",
                        title: "MENUITEMS.TRAINING_CAMP.TEXT",
                        iconType: "material-icons-two-tone",
                        icon: "source",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                        submenu: [],
                    },
                    {
                        path: "/syllabus/discipline",
                        title: "MENUITEMS.TRAINING_DISCIPLINES.TEXT",
                        iconType: "material-icons-two-tone",
                        icon: "source",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                        submenu: [],
                    }
                ],
            },
        ]
    },
    {
        path: "system",
        title: "MENUITEMS.SYSTEM_MANAGER.TEXT",
        iconType: "material-icons-two-tone",
        icon: "settings",
        groupTitle: true,
        role: [],
        submenu: [
            {
                path: "/promo/list",
                title: "Promo code list",
                iconType: "material-icons-two-tone",
                icon: "receipt",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                submenu: [],
            },
            {
                path: "/faq/list",
                title: "FAQ",
                iconType: "material-icons-two-tone",
                icon: "receipt",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                submenu: [],
            },
            // {
            //     path: "/survey-question/list",
            //     title: "Survey",
            //     iconType: "material-icons-two-tone",
            //     icon: "receipt",
            //     groupTitle: false,
            //     role: ["ROLE_SUPER_ADMIN"],
            //     submenu: [],
            // },
            {
                path: "/subscribe/list",
                title: "Subscribe",
                iconType: "material-icons-two-tone",
                icon: "receipt",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN"],
                submenu: [],
            },
            {
                path: "aim",
                title: "MENUITEMS.AIM.TEXT",
                iconType: "material-icons-two-tone",
                icon: "my_location",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN"],
                submenu: [],
            },
            {
                path: "organization",
                title: "MENUITEMS.ORGANIZATION.TEXT",
                iconType: "material-icons-two-tone",
                icon: "domain",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                submenu: [],
            },
            {
                path: "school",
                title: "MENUITEMS.SCHOOL.TEXT",
                iconType: "material-icons-two-tone",
                icon: "school",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                submenu: [],
            },
            {
                path: "",
                title: "MENUITEMS.USERS.TEXT",
                iconType: "material-icons-two-tone",
                icon: "people",
                groupTitle: true,
                role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                submenu: [
                    {
                        path: "teachers",
                        title: "MENUITEMS.TEACHERS.TEXT",
                        iconType: "material-icons-two-tone",
                        icon: "people",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SERVICE"],
                        submenu: [],
                    },
                    {
                        path: "/users/crm",
                        title: "MENUITEMS.USERS.TEXT",
                        iconType: "material-icons-two-tone",
                        icon: "supervisor_account",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                        submenu: []
                    },
                    {
                        path: "custom-registration",
                        title: "Custom registration",
                        iconType: "material-icons-two-tone",
                        icon: "how_to_reg",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                        submenu: [],
                    },
                    {
                        path: "excel-users",
                        title: "Excel-users",
                        iconType: "material-icons-two-tone",
                        icon: "note_add",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                        submenu: [],
                    }
                ]
            },
            {
                path: "/stream/list",
                title: "Stream",
                iconType: "material-icons-two-tone",
                icon: "school",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE", "ROLE_TEACHER"],
                submenu: [],
            },
        ],
    },
    {
        path: "admin",
        title: "MENUITEMS.ADMIN.TEXT",
        iconType: "material-icons-two-tone",
        icon: "folder",
        groupTitle: true,
        role: [],
        submenu: [
            {
                path: "groups",
                title: "Groups",
                iconType: "material-icons-two-tone",
                icon: "group",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SERVICE"],
                submenu: [],
            },
            {
                path: "statistics",
                title: "Statistics",
                iconType: "material-icons-two-tone",
                icon: "bar_chart",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN"],
                submenu: [],
            },
            {
                path: "/exam-schedule",
                title: "Exam schedule",
                iconType: "material-icons-two-tone",
                icon: "bar_chart",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_SERVICE"],
                submenu: [],
            },
            {
                path: "report",
                title: "Report",
                iconType: "material-icons-two-tone",
                icon: "insert_chart_outlined",
                groupTitle: false,
                role: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SERVICE"],
                submenu: [],
            },
            {
                path: "analytics",
                title: "Analytics",
                iconType: "material-icons-two-tone",
                icon: "analytics",
                groupTitle: true,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [
                    {
                        path: "/analytics/questions",
                        title: "Questions",
                        iconType: "material-icons-two-tone",
                        icon: "question_answer",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN"],
                        submenu: [],
                    },
                    {
                        path: "/analytics/student-ratings",
                        title: "Student ratings",
                        iconType: "material-icons-two-tone",
                        icon: "analytics",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN"],
                        submenu: [],
                    },
                    {
                        path: "/analytics/school-ratings",
                        title: "School ratings",
                        iconType: "material-icons-two-tone",
                        icon: "analytics",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN"],
                        submenu: [],
                    }
                ],
            },
            {
                path: "products",
                title: "Products",
                iconType: "material-icons-two-tone",
                icon: "inventory",
                groupTitle: true,
                role: ["ROLE_SUPER_ADMIN", "ROLE_CONTENT_MANAGER"],
                submenu: [
                    {
                        path: "/products",
                        title: "Product List",
                        iconType: "material-icons-two-tone",
                        icon: "source",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN"],
                        submenu: [],
                    },
                    {
                        path: "/products/product-access",
                        title: "Product Access List",
                        iconType: "material-icons-two-tone",
                        icon: "source",
                        groupTitle: false,
                        role: ["ROLE_SUPER_ADMIN"],
                        submenu: [],
                    }
                ],
            },
        ]
    },
];
