import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {NewsFilteredModel} from "../models/filtered/news-filtered.model";
import {constructQueryParameters} from "../functions/query-param.function";
import {ServiceCommonConstants} from "../constants/service-common.constants";

@Injectable({
    providedIn: 'root'
})
export class AdministrationService {

    private readonly GENERAL = `${ServiceCommonConstants.BASE_ADMIN_URL}/api/v1/private/administration-service/employee`;

    constructor(private httpClient: HttpClient) {
    }

    getReportByType(type: number, schoolId: number, date: string, code: string): Observable<any> {
        return this.httpClient.get(`${ServiceCommonConstants.BASE_ADMIN_URL}/api/v1/report?schoolId=${schoolId}&type=${type}&date=${date}&templateCode=${code}`);
    }

    getReportDetailed(params: any): Observable<any> {
        return this.httpClient.get(`${ServiceCommonConstants.BASE_ADMIN_URL}/api/v1/report/detailed` + constructQueryParameters(params));
    }

    getEntResult(params: any): Observable<any> {
        return this.httpClient.get(`${ServiceCommonConstants.BASE_ADMIN_URL}/api/v1/report/ent-result` + constructQueryParameters(params));
    }

    getAllByRolePageable(role: string, params: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/role/${role}/pageable` + constructQueryParameters(params));
    }

    getAllByRoleAndOrgIdPageable(orgId: number, role: string, params: NewsFilteredModel): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/byOrgId/${orgId}/role/${role}/pageable` + constructQueryParameters(params));
    }

    getAllByRoleAndOrgId(orgId: number, role: string): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/byOrgId/${orgId}/role/${role}`);
    }

    getAllByRole(roles: string []): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/role/${roles}`);
    }

    createOrUpdate(data): Observable<any> {
        return this.httpClient.post(this.GENERAL, data);
    }

    deleteById(id: any): Observable<any> {
        return this.httpClient.delete(`${this.GENERAL}/id/${id}`);
    }

    getAllWithoutTeacherAndSuperAdminAndUser(params: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/pageable` + constructQueryParameters(params));
    }

    getAllByOrgId(orgId: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/${orgId}`);

    }

    getEmployeeById(id: any) {
        return this.httpClient.get(`${this.GENERAL}/by/id/${id}`);
    }
}
